<template>
    <div class="outDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-table
            border
            stripe
            style="width: 100%"
            :data="tableData"
            size="mini"
            show-summary
            :highlight-current-row="true"
            max-height="500"
            v-loading="showAlllist"
            :default-sort="{ prop: 'showPrices', order: 'descending' }"
        >
            <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
            <el-table-column prop="sku.name" label="商品名称" width="180" />
            <el-table-column label="条码">
                <template slot-scope="scope">
                    <span>{{ scope.row.sku.bars.map((d) => d).join(' , ') }}</span>
                </template>
            </el-table-column>
            <el-table-column label="商品类型" width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.sku.type | goodsType }}</span>
                </template>
            </el-table-column>
            <el-table-column label="助记码" width="100">
                <template slot-scope="scope">
                    {{ scope.row.fastBar }}
                </template>
            </el-table-column>
            <el-table-column label="自编码" width="100">
                <template slot-scope="scope">
                    {{ scope.row.customBar }}
                </template>
            </el-table-column>
            <el-table-column label="规格" width="100">
                <template slot-scope="scope">
                    {{ scope.row.sku.specs }}
                </template>
            </el-table-column>
            <el-table-column prop="sku.category" label="类目" width="120" />
            <el-table-column prop="sku.unit" label="单位" width="80" />
            <el-table-column label="电商退货数量" sortable prop="count" />
            <el-table-column label="电商退款金额" sortable prop="goodsRefundAmountYuan" />
        </el-table>
    </div>
</template>
<script>
import MoneyUtils from 'js/MoneyUtils';
import GoodsCommon from 'js/GoodsCommon';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'OutDetail',
    // props: {
    //     code: {
    //         type: String,
    //         required: true,
    //     },
    // },
    data() {
        return {
            showAlllist: true,
            form: {
                code: '',
                createTime: '',
                creator: '',
                updater: '',
                updateTime: '',
                stockStatus: '',
                deptName: '',
                purchaseDeptName: '',
                deptCode: '',
                customer: '',
                customerMobile: '',
                customerAddress: '',
                detailParams: [],
            },
            tableData: [],
            codeMap: [],
            url: {
                queryGoods: '/goods/goods/list_c',
            },
            //所选商品详情列表
            goodsDetailMap: new Map(),
            hasBigUnitPrivilege: false,
        };
    },
    mounted() {
        //查询数据
        this.form.code = this.$route.params.code;
        this.handleQueryGoods();
    },
    methods: {
        //查询数据
        handleQueryGoods() {
            const _this = this;
            let dataArr = [];
            UrlUtils.QueryRemote(this, '/fromMultishop/onlineShoppingRefund/detail/' + this.form.code, (rst) => {
                dataArr = rst;
                rst.forEach((v, i) => {
                    _this.codeMap.push(v.goodsCode);
                });
            });
            setTimeout(() => {
                if (this.codeMap.length) {
                    UrlUtils.QueryGoodsInfoByCodes(this, this.url.queryGoods, { codes: this.codeMap }, (data) => {
                        data.forEach((v, i) => {
                            dataArr.forEach((item, idx) => {
                                if (item.goodsCode === v.code) {
                                    item.sku = v.sku;
                                    item.fastBar = v.fastBar;
                                    item.customBar = v.customBar;
                                    // item.sku = v.sku
                                    // dataArr = dataArr.concat(item.sku,item.count,item.goodsActualTotal)
                                    // item.count = v.count
                                    // item.goodsActualTotal = v.goodsActualTotal
                                }
                            });
                            this.tableData = dataArr;
                            this.showAlllist = false;
                        });
                    });
                }
            }, 350);
            this.showAlllist = false;
        },
        computeCount(detailParam) {
            let counts;
            if (this.hasBigUnitPrivilege) {
                //启用大单位
                counts = this.decimal(detailParam.packFactors || 1)
                    .mul(detailParam.bigUnitCounts || 0)
                    .add(detailParam.bulkCounts || 0)
                    .toNumber();
            } else {
                //未启用大单位
                counts = detailParam.counts || 0;
            }
            return counts;
        },
        computeShowMoney(detailParam) {
            return this.decimal(detailParam.counts || this.computeCount(detailParam))
                .mul(detailParam.showPrices || 0)
                .toNumber();
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
        m(m) {
            return MoneyUtils.formatMoney(m);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
